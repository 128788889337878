import React from "react"

import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Image from "../components/image"
import Icon from "../components/icon"
import SEO from "../components/seo"
import StyledCard from "../components/StyledCard"

import styled from "styled-components"
import { createGlobalStyle } from "styled-components"

import Apercu from "../fonts/apercu/Apercu.ttf"
import ApercuBold from "../fonts/apercu/Apercu-Bold.otf"

import ReactGA from 'react-ga'
ReactGA.initialize('UA-120656560-3')
if (typeof(window) !== 'undefined')
	ReactGA.pageview(window.location.pathname + window.location.search)

const IndexPage = ({ className }) => (
	<StaticQuery
		query={graphql`
			query {
				desktop: file(relativePath: { eq: "funk-milk.png" }) {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`}
		render={data => {
			return (
				<StyledBackgroundImage
					Tag="section"
					className={className}
					fluid={data.desktop.childImageSharp.fluid}
					backgroundColor={`#040e18`}
				>
					<GlobalStyles />
					<SEO title="Home" />
						<br />
						<StyledCard>
							<h1 style={{ fontFamily: "Apercu-Bold", fontSize: "28px", textAlign: "center", margin: ".125em 0", letterSpacing: -0.25 }}>PAITHOS</h1>
							<p style={{ fontFamily: "Apercu-Bold", fontSize: "12px", textAlign: "center", color: "darkgray", padding: 0, margin: 0, lineHeight: 1.5 }}>compelling media</p> 
							<p style={{ fontFamily: "Apercu-Bold", fontSize: "12px", textAlign: "center", color: "darkgray", padding: 0, margin: 0, lineHeight: 1.25 }}>with AI at its core</p>
							<div className="card_image" style={{ maxWidth: "14em", margin: "1.45em auto" }}>
								<Image />
							</div>
							<div style={{ maxWidth: "1.7em", margin: "0 auto" }}>
								<Icon />
							</div>
						</StyledCard>
						<br />
				</StyledBackgroundImage>
			)
		}}
	/>
)

const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: "Apercu";
		src: url(${Apercu});
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: "Apercu-Bold";
		src: url(${ApercuBold});
		font-weight: bold;
		font-style: normal;
	}

	body {
		margin: 0;
	}
`

const StyledBackgroundImage = styled(BackgroundImage)`
	width: 100%;
	background-position: center;
	background-repeat: repeat-y;
	background-size: cover;
	min-height: 100vh;
`

export default IndexPage
